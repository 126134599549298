.footerContent {
  background-color: #1d695b;
  display: flex;
  align-items: center;
  padding: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 780px) {
  }

  p {
    color: $white;
  }

  &-social {
    h4 {
      color: white;
      font-style: italic;
      margin-bottom: 10px;
    }

    ul {
      list-style-type: none;
      margin-bottom: 20px;

      li {
        cursor: pointer;
        color: $orange;
      }
    }

    &-icons {
      display: flex;

      svg {
        font-size: 30px;
        margin-right: 10px;
        color: $orange;
      }
    }
  }

  &-form {
    max-width: 50%;
    min-width: 500px;
    @media screen and (max-width: 880px) {
      max-width: inherit;
      min-width: inherit;
    }

    &-content-txt {
      text-align: center;
      & span {
        color: $orange;
      }
    }
  }
}

.pepiteggs-container {
  background: black;
  color: white;
  text-align: center;
  padding: 3px 0;
}

.pepiteggsContent {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }
  .pepiteggs {
    color: greenyellow;
  }
}

.is-active {
  display: initial;
}

.is-inactive {
  display: none;
}

.showPepiteggs {
  cursor: pointer;
}
