.scroll-top-button {
    width: 30px;
    background: white;
    display: flex;
    height: 30px;
    border-radius: 20px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.3s ease;
    align-items: center;
    justify-content: center;
    background: $green;
    @media only screen and (max-width: 768px) {
        bottom: 15px;
        right: 15px;
    }

    path {
        color: $white;
    }

    &:hover {
        opacity: 1;
    }
}
