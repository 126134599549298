.decoration-container {
    h2 {
        margin: 3rem 0;
    }
    &-services {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            flex-wrap: wrap;
            align-items: inherit;
            justify-content: center;
        }

        @media screen and (min-width: 768px) and (max-width: 1200px) {
            justify-content: space-evenly;
            margin: 1rem 0;
        }

        &-card {
            width: 80%;
            margin: 3rem 0;
            border-radius: 10px;
            box-shadow: 0 0 10px lightgrey;
            overflow: hidden;
            &:hover {
                box-shadow: 0 0 10px $lighGreen;
                background-color: $lighGreen;
                color: $white;
                transition: 0.15s linear !important;
            }

            img {
                width: 100%;
            }
            @media screen and (min-width: 768px) {
                width: 40%;
                max-width: 500px;
                text-align: justify;
                border-radius: 10px;
                box-shadow: 0 0 10px lightgrey;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 3rem;
                padding: 0;
            }

            @media screen and (min-width: 768px) and (max-width: 1200px) {
                margin: 1rem;
            }

            h4 {
                margin-top: 1rem;
                text-align: center;
            }

            ul {
                margin-bottom: 1rem;
                padding: 1rem;
                @media screen and (min-width: 768px) {
                    margin: 1rem;
                }

                li {
                    list-style-type: none;
                }
            }
        }
    }

    .priceTagContainer {
        display: flex;
        justify-content: flex-end;
        @media screen and (min-width: 768px) {
            margin: 1rem 0;
            padding-right: 1rem;
        }

        &-price {
            width: 100px;
            height: 50px;
            border-radius: 2px;
            background-color: $lighGreen;
            box-shadow: 0 0 5px 1px #7fdccb;
            color: white;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 768px) {
                margin: 0;
            }
        }
    }

    .realisation-container {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &-card {
            margin: 20px;
            box-shadow: 0 0 7px 2px grey;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.services-array {
    width: 90%;
    border: 1px solid $orange;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    box-shadow: none;
    &:hover {
        box-shadow: none;
        background-color: inherit;
        color: inherit;
    }

    @media screen and (min-width: 768px) {
        width: 100%;
        max-width: none;
        height: auto;
    }

    &-wrapper {
        margin: 100px 0;
        &:hover {
            box-shadow: 0 0 10px $lighGreen;
            background-color: $lighGreen;
            color: $white;
            transition: 0.15s linear !important;
        }
        @media screen and (min-width: 768px) {
            width: 30%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-shadow: 0 0 10px lightgrey;
            margin: 1rem;
        }
    }

    &-desktop-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }
}

.justify-content-center {
    justify-content: center !important;
}
