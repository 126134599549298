.about-container {
    .main-wrapper {
        @media screen and (min-width: 768px) {
            max-width: 1200px;
            margin: 0 5rem;
        }
    }
    .profil-wrapper {
        display: flex;
        padding: 1rem;
        margin: 3rem 0;

        .profil-picture {
            width: 200px;
            height: 200px;
            position: relative;
            left: 35px;
            top: 35px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100px;
                box-shadow: 0 0 7px 1px grey;
            }

            &-border1 {
                border: 1px solid $orange;
                width: 250px;
                height: 250px;
                position: absolute;
                opacity: 0.5;
            }

            &-border2 {
                border: 1px solid $green;
                width: 250px;
                height: 250px;
                position: relative;
                left: 20px;
                top: 20px;
            }
        }
    }

    .introduction-container {
        padding: 1rem;
        margin: 5rem 0;

        h2 {
            font-style: italic;
            font-weight: 500;
        }

        .introduction-wrapper {
            display: flex;
            margin: 3rem 0;

            &-border1 {
                border: 1px solid $orange;
                width: 80%;
                height: 350px;
                position: absolute;
                left: 40px;
                opacity: 0.5;
            }

            &-border2 {
                border: 1px solid $green;
                width: 100%;
                height: 350px;
                position: relative;
                left: 20px;
                top: 70px;
            }
        }
    }
}
