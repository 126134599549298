.contact-container {
    .card-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-evenly;
            margin: 3rem 0;
        }

        &-card {
            width: 80%;
            height: 200px;
            border: 1px solid $orange;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin: 1rem 0;
            cursor: pointer;
            &:hover {
                box-shadow: 0 0 10px $orange;
                background-color: $orange;
                color: $white;
                transition: 0.15s linear !important;
            }
            @media screen and (min-width: 768px) {
                width: 30%;
            }

            svg {
                font-size: 40px;
            }
        }
    }

    h1 {
        text-align: center;
        margin: 3rem 0;
    }
}
