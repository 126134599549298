$color1: #22223b;
$color2: #4a4e69;
$color3: #9a8c98;
$color4: #c9ada7;
$color5: #f2e9e4;

$color6: #9c6644;
$color7: #7f5539;
$color8: #b08968;
$color9: #ddb892;
$color10: #e6ccb2;
$color11: #ede0d4;

$white: #fff;
$black: #000;
$green: #1d695b;
$lighGreen: #7fdccb;
$orange: #eb7a44;
