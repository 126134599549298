.notFound-container {
    &-txt {
        margin: 3rem;
        text-align: center;
        height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            margin-bottom: 3rem;
        }
    }
}
