* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-display: swap;
    font-family: "Work Sans", sans-serif;
}

html {
    background: $white;
    color: $green;
    font-size: calc(15px + 0.390625vw);
}

body {
    min-height: 100vh;
    width: 100%;
}

#CybotCookiebotDialog {
    @media screen and (max-width: 780px) {
        width: 200px !important;
    }
}

h1 {
    font-family: "Forum", cursive;
    font-size: calc(40px + 0.390625vw);
}
