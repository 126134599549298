.navbar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.burger-menu {
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
    margin: 20px;
    @media screen and (min-width: 1200px) {
        display: none;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
        color: $orange;
    }

    &-close {
        cursor: pointer;
    }
}

.line {
    width: 25px;
    height: 3px;
    margin: 5px;
    background-color: #333;
}

.nav-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    @media screen and (min-width: 768px) {
        justify-content: space-around;
        svg {
            display: none;
        }
    }
}

.nav-links li {
    list-style-type: none !important;
    width: 120px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    &:hover {
        background-color: $orange;
        box-shadow: 0 0 5px 1px $orange;

        & a {
            color: $white;
        }
    }
}

.nav-links a {
    text-decoration: none;
    color: $color5;
    font-weight: bold;
    font-size: 16px;
    transition: all 0.3s ease;
    @media screen and (min-width: 768px) {
        color: $orange;
    }
}

.nav-links a:hover {
    color: $white;
}

@media (max-width: 1200px) {
    .nav-links {
        display: none;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        height: 100vh;
        background-color: $green;
        flex-direction: column;
        transition: transform 0.5s ease-in;
        justify-content: space-evenly;
    }

    .nav-links.active {
        width: 100%;
        display: flex;
        z-index: 1;

        svg {
            font-size: 30px;
            color: #f2e9e4;
        }
    }

    .burger-menu {
        display: block;
    }
}
