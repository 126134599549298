button {
  width: 150px;
  height: 50px;
  border: 1px solid $orange;
  border-radius: 5px;
  background-color: transparent;
  color: $green;

  &:hover {
    // animation: btn-hover 0.3s ease-in-out both;
  }
}

@keyframes btn-hover {
  100% {
    background-color: $green;
    color: $white;
    font-size: 17px;
    border-radius: 0;
    border: none;
  }
}
